<template lang="pug">
  vue-login
</template>

<script>

import Login from "@/components/auth/LoginQuick"

// Constantes
const COMPONENT_NAME= "Login"

export default {
  name: COMPONENT_NAME,
  components: {
    "vue-login": Login
  }
}
</script>
