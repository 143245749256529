import HTTP from "@/http-common.js"

export default {
  recoveryPassword(data) {
    let request = [
      '/rest-auth/password/reset/',
      data
    ]
    return new Promise((response, reject) => {
      HTTP.post(...request).then(response).catch(reject)
    })
  },
}