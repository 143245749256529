<template lang="pug">
  div#loginContainer
    video(
      ref="videoBackground"
      :class="pageOrientation"
      loop
      autoPlay
      muted
    )
      source(
        src="../../assets/video/video_login.mp4"
        type="video/mp4"
      )
    div#sidebarForm
      div#sidebarScroll
        div#headerForm
          img(
            style="height:110px; width:330px;"
            :src="require('@/assets/images/logo/logo_total.png')"
            alt="logo"
          )
        div#inputContainer
          p.text-center.text-base {{ $t('auth.welcome')}}
          div.inputLogin.mt-3.mx-3
            label.text-sm {{ $t('auth.email') }}
            vs-input.w-full.mt-1(
              type="email"
              icon-pack="feather"
              icon="icon icon-user"
              v-validate="'required|email|min:3'"
              name="email"
              :data-vv-as="$t('auth.email')"
              v-model="email"
              icon-no-border
            )
          span.text-danger.text-sm.mx-3(
            v-show="errors.has('email')"
          ) {{ errors.first('email') }}
          vx-input-group.mt-2.mx-3
            label.text-sm {{ $t('auth.password') }}
            vs-input.w-full.mt-1(
              :type="passwordInputType"
              icon-pack="feather"
              icon="icon icon-lock"
              v-validate="'required'"
              name="password"
              :data-vv-as="$t('auth.password')"
              autocomplete="off"
              v-model="password"
            )
            template(
              slot="append"
            )
              div#buttonPassword
                a(
                  class="vs-component vs-button vs-button-primary vs-button-filled includeIcon includeIconOnly rounded-l-none"
                  color="primary"
                  style="color: black"
                  vslor="primary"
                  @click.prevent="switchVisibility"
                )
                  i(
                    :class="iconButtonPassword"
                  )
          span.text-danger.text-sm.mx-3(
            v-show="errors.has('password')"
          ) {{ errors.first('password') }}
          //- a.mt-2.self-center.cursor-pointer.text-sm.font-bold(
          //-   style="color: black; border-radi"
          //-   @click.prevent="forgotPassword"
          //- ) {{$t('auth.forgot')}}
          change-language.self-center
        div#buttonsContainer
          vs-button.text-lg.font-bold.borderButton.sizeButton(
            :disabled="isDisabledSaveButton"
            @click.prevent="recaptcha"
          ) {{ $t('auth.entrar') }}
          //- vs-button.text-2xl.mt-5.font-bold.borderButton.sizeButton(
          //-   style=" background: white !important"
          //-   :disabled="isDisabledSaveButton"
          //-   @click.prevent="recaptcha"
          //- ) {{ $t('auth.register') }}
</template>
<script>
import { VueReCaptcha } from "vue-recaptcha-v3"
import Vue from "vue"
import JwtService from "@/jwt-service"//eslint-disable-line
import { emitRegister } from "@/SocketManager.js"//eslint-disable-line
import Login from "@/api/auth/Login"
import ErrorNotify from "@/components/errors/Errors"
import ChangeLanguage from "@/components/lang/ChangeLanguage"

Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_KEY })

export default {
  components: {
    ChangeLanguage
  },
  data() {
    return {
      pageOrientation: "landscape",
      email: "",
      password: "",
      token: "",
      emailRecovery: "",
      passwordInputType: "password",
      iconButtonPassword: "fas fa-eye-slash",
      isDisabledSaveButton: false
    }
  },
  mounted() {
    this.onResize()
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize)
    })
    this.$store.commit("auth/SET_USER_TOKEN", null)
    if (localStorage.getItem("user_name")) {
      this.email = localStorage.getItem("user_name")
    }
    const recaptcha = this.$recaptchaInstance
    if (recaptcha) {
      recaptcha.showBadge()
    }
  },
  methods: {
    onResize() {
      setTimeout(() => {
        let videoBackground = this.$refs.videoBackground
        let videoHeight = videoBackground.clientHeight
        let videoWidth = videoBackground.clientWidth
        if (videoHeight < window.innerHeight) {
          this.pageOrientation = "portrait"
        }
        if (videoWidth < window.innerWidth) {
          this.pageOrientation = "landscape"
        }
      }, 100)
    },
    switchVisibility() {
      this.passwordInputType = this.passwordInputType === "password" ? "text" : "password"
      this.iconButtonPassword = this.iconButtonPassword === "fas fa-eye-slash" ? "fas fas fa-eye " : "fas fa-eye-slash"
    },
    forgotPassword() {
      this.$swal({
        position: "center",
        input: "text",
        title: this.$t("auth.recovery"),
        text: this.$t("auth.recovery_message"),
        confirmButtonColor: "#FDD402",
        showConfirmButton: true,
        confirmButtonText: this.$t("auth.recovery_button"),
        preConfirm: emailRecovery => {
          if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailRecovery)) {//eslint-disable-line
            this.emailRecovery = emailRecovery
          } else {
            if (emailRecovery) {
              setTimeout(() => {
                this.$swal({
                  position: "center",
                  type: "warning",
                  title: this.$t("auth.email-plataform-invalid"),
                  confirmButtonColor: "#FDD402",
                  cancelButtonColor: "#ea5455",
                  allowOutsideClick: false,
                  timer: 3000
                })
              }, 500)
            }
          }
        }
      }).then(() => {
        if (this.emailRecovery) {
          Login.recoveryPassword({ email: this.emailRecovery })
            .then(() => {
              this.alertSucessRestore()
            })
            .catch(error => {
              ErrorNotify.formsErrors({
                data: error.response,
                ob: this
              })
            })
        }
      })
    },
    alertSucessRestore() {
      this.$swal({
        position: "center",
        type: "success",
        title: this.$t("auth.email-plataform"),
        confirmButtonColor: "#FDD402",
        cancelButtonColor: "#ea5455",
        allowOutsideClick: false,
        timer: 3000
      })
    },
    async recaptcha() {
      this.login()
    },
    login() {
      this.$validator.validateAll().then(result => {
        if (result) {
          let dataLogin = {
            data: {
              email: this.email,
              password: this.password
            },
            this: this
          }
          this.isDisabledSaveButton = true
          this.$store
            .dispatch("auth/login", dataLogin)
            .then(response => {// eslint-disable-line
              this.isDisabledSaveButton = false
              this.clearMap()
              this.loginAlert()
            })
            .catch(error => {// eslint-disable-line
              this.isDisabledSaveButton = false
            })
        }
      })
    },
    clearMap() {
      this.$store.dispatch("map/geojsonSet", [])
      this.$store.dispatch("map/geojsonRoute", [])
      this.$store.dispatch("map/markersService", [])
      this.$store.dispatch("map/geoJsonPointSet", [])
      this.$store.dispatch("map/tracingPoint", [])
      // this.$store.dispatch("map/tracingMarkersArray", [])
      this.$store.dispatch("map/markArrayPoint", [])
      this.$store.dispatch("map/tracingStatusArray", [])
      this.$store.dispatch("map/setPolylineArray", [])
      this.$store.dispatch("map/geojsonZoneSet", [])
    },
    loginAlert() {
      this.$swal({
        position: "center",
        type: "success",
        title: this.$t("welcome-plataform"),
        confirmButtonColor: "#FDD402",
        cancelButtonColor: "#ea5455",
        allowOutsideClick: false,
        timer: 1000
      }).then(async result => {
        if (result) {
          // await this.$store.dispatch("config/setConfig")
          this.$router.push({ name: "IndexHome" })
          let userInfo = JSON.parse(localStorage.getItem("userInfo"))//eslint-disable-line
        }
      })
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize)
  }
}
</script>
<style>
#loginContainer {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

#sidebarForm {
  width: 400px;
  height: 100vh;
  right: 0;
  top: 0;
  position: absolute;
  overflow-y: auto;
  background-color: #ffffffcf;
}

#sidebarScroll {
  height: 100%;
  width: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

#headerForm {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

#inputContainer {
  height: 30%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: black !important;
  padding: 30px;
}

#inputContainer .vs-con-input-label.is-label-placeholder {
  margin-top: 30px !important;
}

#inputContainer .vs-input--placeholder,
#inputContainer .vs-input--icon {
  color: black !important;
}

#inputContainer .vs-inputx {
  border: 1px solid black !important;
  border-radius: 10px;
}

.inputLogin {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#buttonPassword {
  margin-top: 24px;
}

#buttonsContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

#buttonsContainer .borderButton {
  border: 1px solid black;
  border-radius: 15px;
}

#buttonsContainer .sizeButton {
  width: 40%;
  height: 40px;
}

.swal2-actions button {
  color: black !important;
}

.landscape {
  width: 100vw;
  height: auto;
  z-index: -1;
}

.portrait {
  height: 100vh;
  width: auto;
  z-index: -1;
}
</style>